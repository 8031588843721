import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NewsItem } from 'services/hr-cockpit/news';
import { NewsService } from 'services/hr-cockpit/news.service';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  @ViewChild('ionCard', { read: ElementRef }) ionCard: ElementRef;
  @ViewChild('ionCard') swiper: ElementRef<SwiperContainer>;

  gridStackColumnCount: number;
  slidesPerView = 1;
  lineClamp: number;
  allNewsItems: NewsItem[];
  filteredNewsItems: NewsItem[];
  slideItemMinWidth = 300; // könnte entfernt werden, wenn wir keine min-width wollen

  observer = new ResizeObserver(entries => {
    entries.forEach(entry => {
      const windowWidth = window.innerWidth;
      // according to GridStackOptions breakpoints in hr-cockpit.page.ts
      if (windowWidth < 750) {
        this.gridStackColumnCount = 2;
      } else if (windowWidth < 1000) {
        this.gridStackColumnCount = 4;
      } else {
        this.gridStackColumnCount = 12;
      }
      const lineHeight = parseFloat(getComputedStyle(entry.target).lineHeight);
      // const itemWidth = window.innerWidth / (this.gridStackColumnCount / 2);
      const itemWidth = Math.max(window.innerWidth / (this.gridStackColumnCount / 2), this.slideItemMinWidth);
      this.slidesPerView = Math.max(1, Math.round(entry.contentRect.width / itemWidth));

      if (
        entry.target.querySelector('.news-item__title') &&
        entry.target.querySelector('.news-item__img-container') &&
        entry.target.querySelector('.news-item__date') &&
        entry.target.querySelector('.news-item__button')
      ) {
        const titleHeight = parseFloat(getComputedStyle(entry.target.querySelector('.news-item__title')).height);
        const imgHeight = parseFloat(getComputedStyle(entry.target.querySelector('.news-item__img-container')).height);
        const dateHeight = parseFloat(getComputedStyle(entry.target.querySelector('.news-item__date')).height);
        const buttonHeight = parseFloat(getComputedStyle(entry.target.querySelector('.news-item__button')).height);

        const paragraphHeight = (entry.contentRect.height
          - titleHeight
          - imgHeight
          - dateHeight
          - buttonHeight
          - 36 // ion-card padding-block
          - 37 // ion-grid padding-block
          - 16 // news-item__title margin-bottom
          - 8 // news-item__text margin-block
        );
        this.lineClamp = Math.max(4, Math.round(paragraphHeight / lineHeight));
      }
    });
  });

  constructor(
    private readonly newsService: NewsService,
  ) { }

  getAllNewsItems() {
    return firstValueFrom(this.newsService.getAllNewsItems());
  }

  async ngOnInit() {
    this.allNewsItems = await this.getAllNewsItems();
    this.filteredNewsItems = this.filterNews(this.settings.themeFocus);
    requestAnimationFrame(() => {
      this.swiper.nativeElement.swiper.updateSlides();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.settings || !changes.settings.previousValue) {
      return;
    }
    this.filteredNewsItems = this.filterNews(changes.settings.currentValue.themeFocus);
    requestAnimationFrame(() => {
      this.swiper.nativeElement.swiper.updateSlides();
    });
  }

  ngAfterViewInit() {
    this.observer.observe(this.ionCard.nativeElement);
  }

  filterNews(themes: string[]) {
    return this.allNewsItems.filter(news =>
      news.theme.some((theme: string) => themes.includes(theme))
    );
  }
}
