// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  padding-bottom: 1rem;
  text-align: center;
  top: 0;
}

header.row {
  top: 35.2px;
  border-bottom: 1px solid var(--text-color);
}

.sticky {
  position: sticky;
  z-index: 5;
  background-color: white;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  text-align: center;
}
.row span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.row__id {
  flex: 0 0 10%;
}
.row__process {
  flex: 1 1 auto;
  text-align: left;
  padding-left: 1rem;
}
.row__count {
  flex: 0 0 15%;
  text-align: center;
}
.row__count--margin-right {
  margin-right: 20px;
}
.row__icon {
  flex: 0 0 auto;
  color: var(--color-two);
  font-size: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/chat-messages/chat-messages.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,MAAA;AACF;;AAEA;EACE,WAAA;EACA,0CAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;AAEE;EACE,aAAA;AAAJ;AAGE;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,kBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,cAAA;EACA,uBAAA;EACA,kBAAA;AAJJ","sourcesContent":["h2 {\n  padding-bottom: 1rem;\n  text-align: center;\n  top: 0;\n}\n\nheader.row {\n  top: 35.2px; // height of h2 (content + padding + border)\n  border-bottom: 1px solid var(--text-color);\n}\n\n.sticky {\n  position: sticky;\n  z-index: 5;\n  background-color: white;\n}\n\n.row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 0.5rem;\n  padding-right: 1rem;\n  text-align: center;\n\n  span {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  &__id {\n    flex: 0 0 10%;\n  }\n\n  &__process {\n    flex: 1 1 auto;\n    text-align: left;\n    padding-left: 1rem;\n  }\n\n  &__count {\n    flex: 0 0 15%;\n    text-align: center;\n  }\n\n  &__count--margin-right {\n    margin-right: 20px; // width of .messages__item__icon\n  }\n\n  &__icon {\n    flex: 0 0 auto;\n    color: var(--color-two);\n    font-size: 1.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
