import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LinkOption } from 'services/hr-cockpit/links';
import { LinksService } from 'services/hr-cockpit/links.service';
@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent  implements OnInit, OnChanges, AfterViewInit {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  @ViewChild('links') links;

  defaultLinks: LinkOption[] = [];
  individualLinks: LinkOption[] = [];

  constructor(
    private linksService: LinksService,
  ) { }

  async ngOnInit() {
    this.defaultLinks = await this.updateDefaultLinks();
    this.toggleDefaultLinks(this.settings.linkSelection);
    this.updateIndividualLinks(this.settings.individualLinks);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.settings || !changes.settings.previousValue) {
      return;
    }
    this.toggleDefaultLinks(changes.settings.currentValue.linkSelection);
    this.updateIndividualLinks(changes.settings.currentValue.individualLinks);
  }

  ngAfterViewInit() {
    const cardContent = this.links.el.parentNode.parentNode as HTMLElement;
    cardContent.style.overflowY = 'auto';
  }

  toggleDefaultLinks(linkTitles: string[]) {
    if (!linkTitles) {
      return;
    }
    linkTitles.map((linkTitle: string) => linkTitle.toLowerCase());
    this.defaultLinks.forEach((link: LinkOption) => {
      link.isShown = linkTitles.includes(link.title.toLowerCase());
    });
  }

  async updateDefaultLinks() {
    return firstValueFrom(this.linksService.getDefaultLinks());
  }

  updateIndividualLinks(links: LinkOption[]) {
    if (links) {
      this.individualLinks = links;
    }
  }
}
