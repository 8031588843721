// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  margin-top: 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
}

.action {
  display: flex;
  gap: 2rem;
}
.action__item {
  line-height: 44px;
}
.action__item--text {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action__item--id {
  flex: 0 0 1rem;
  text-align: center;
}
.action__item--link {
  flex: 0 0 12rem;
  margin-right: 0.5rem;
  --border-color: var(--color-two);
  --color: var(--color-two);
  --border-radius: 100px;
  --background-hover-opacity: 1;
  --background-hover: var(--color-two);
  --color-hover: var(--on-highlight-color);
}

.action:last-child {
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/last-actions/last-actions.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,aAAA;EAEA,gBAAA;EACA,MAAA;EACA,uBAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AADJ;AAIE;EACE,cAAA;EACA,kBAAA;AAFJ;AAKE;EACE,eAAA;EAEA,oBAAA;EACA,gCAAA;EACA,yBAAA;EACA,sBAAA;EACA,6BAAA;EACA,oCAAA;EACA,wCAAA;AAJJ;;AAQA;EACE,qBAAA;AALF","sourcesContent":["h2 {\n  text-align: center;\n  font-size: 1.5rem;\n  padding-bottom: 1rem;\n  margin-top: 0;\n\n  position: sticky;\n  top: 0;\n  background-color: white;\n  z-index: 5;\n}\n\n.action {\n  display: flex;\n  gap: 2rem;\n\n  &__item {\n    line-height: 44px;\n  }\n\n  &__item--text {\n    flex: 1 1 auto;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  &__item--id {\n    flex: 0 0 1rem;\n    text-align: center;\n  }\n\n  &__item--link {\n    flex: 0 0 12rem;\n\n    margin-right: 0.5rem;\n    --border-color: var(--color-two);\n    --color: var(--color-two);\n    --border-radius: 100px;\n    --background-hover-opacity: 1;\n    --background-hover: var(--color-two);\n    --color-hover: var(--on-highlight-color);\n  }\n}\n\n.action:last-child {\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
