import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChatMessagesService } from 'services/hr-cockpit/chat-messages.service';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
})
export class ChatMessagesComponent  implements OnInit, OnChanges {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  messages = [];

  constructor(private chatMessagesService: ChatMessagesService) { }

  ngOnInit() {
    this.chatMessagesService.getChatMessages(this.settings.dataSelection)
      .subscribe((messages) => {
        this.messages = messages;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.settings || !changes.settings.previousValue) {
      return;
    }

    this.chatMessagesService
      .getChatMessages(changes.settings.currentValue.dataSelection)
        .subscribe((messages) => {
          this.messages = messages;
        });
  }

}
