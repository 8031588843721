import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Action } from 'services/hr-cockpit/action';

@Injectable({
  providedIn: 'root'
})
export class LastActionsService {

  constructor(private http: HttpClient) { }

  // Default parameter values for widgetShop
  getLastActions(source: string = 'candidates', count: number = 5): Observable<Action[]> {
    return this.http.get<Action[]>(
      `/api/hr-cockpit/lastActions?source=${source}&count=${count}`
    );
  }
}
