// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1.2px;
}

ion-list {
  margin-inline: 0.5rem;
}

img {
  margin: 0.5rem 1rem 0.5rem 0;
}

.opacity_0 {
  opacity: 0;
}

ion-item:hover {
  --color: #fff;
}

ion-label {
  border-radius: 100px;
  border: 1px solid var(--color-two);
  padding: 0.25rem;
  text-align: center;
  color: var(--color-two) !important;
  font-weight: 500;
}

ion-label:hover,
ion-label.selected {
  background-color: var(--color-two);
  color: white !important;
}

ion-button {
  padding: 0;
  align-self: flex-start;
  --border-color: var(--color-two);
  --color: var(--color-two);
  --border-radius: 100px;
  --background-hover-opacity: 1;
  --background-hover: var(--color-two);
  --color-hover: var(--on-highlight-color);
}

.link-item {
  display: flex;
  align-items: center;
}
.link-item__button {
  flex-grow: 1;
  margin: unset;
}
.link-item__icon {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/links/links.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,oBAAA;EACA,kCAAA;EACA,gBAAA;EACA,kBAAA;EACA,kCAAA;EACA,gBAAA;AACF;;AAEA;;EAEE,kCAAA;EACA,uBAAA;AACF;;AAEA;EACE,UAAA;EAEA,sBAAA;EACA,gCAAA;EACA,yBAAA;EACA,sBAAA;EACA,6BAAA;EACA,oCAAA;EACA,wCAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,YAAA;EACA,aAAA;AAAJ;AAGE;EACE,aAAA;AADJ","sourcesContent":["h2 {\n  text-align: center;\n  font-weight: 600;\n  letter-spacing: 1.2px;\n}\n\nion-list {\n  margin-inline: 0.5rem;\n}\n\nimg {\n  margin: 0.5rem 1rem 0.5rem 0;\n}\n\n.opacity_0 {\n  opacity: 0;\n}\n\nion-item:hover {\n  --color: #fff;\n}\n\nion-label {\n  border-radius: 100px;\n  border: 1px solid var(--color-two);\n  padding: 0.25rem;\n  text-align: center;\n  color: var(--color-two) !important;\n  font-weight: 500;\n}\n\nion-label:hover,\nion-label.selected {\n  background-color: var(--color-two);\n  color: white !important;\n}\n\nion-button {\n  padding: 0;\n  // margin: 1rem 0 0;\n  align-self: flex-start;\n  --border-color: var(--color-two);\n  --color: var(--color-two);\n  --border-radius: 100px;\n  --background-hover-opacity: 1;\n  --background-hover: var(--color-two);\n  --color-hover: var(--on-highlight-color);\n}\n\n.link-item {\n  display: flex;\n  align-items: center;\n\n  &__button {\n    flex-grow: 1;\n    margin: unset;\n  }\n\n  &__icon {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
