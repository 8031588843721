import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { LastActionsService } from 'services/hr-cockpit/last-actions.service';

@Component({
  selector: 'app-last-actions',
  templateUrl: './last-actions.component.html',
  styleUrls: ['./last-actions.component.scss'],
})
export class LastActionsComponent  implements OnInit, OnChanges {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  actions: any[] = [];

  constructor(private lastActionsService: LastActionsService) { }

  ngOnInit() {
    this.lastActionsService.getLastActions(
      this.settings.source,
      this.settings.processCount
    )
      .subscribe((actions) => {
        this.actions = actions;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.settings || !changes.settings.previousValue) {
      return;
    }

    this.lastActionsService.getLastActions(
      changes.settings.currentValue.source,
      changes.settings.currentValue.processCount
    ).subscribe((actions) => {
        this.actions = actions;
    });
  }

}
