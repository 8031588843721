import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsItem } from './news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllNewsItems(): Observable<NewsItem[]> {
    return this.http.get<NewsItem[]>(
      `/api/hr-cockpit/allNewsItems`
    );
  }
}
